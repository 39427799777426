.fm-alert {
  padding: 8px 16px;
  color: $fmBlack;
  background-color: $alertErrorBackgroundColor;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  box-shadow: 0 -0.05rem 1rem rgba(0, 0, 0, 0.175) !important;
  display: flex;
  padding-right: 64px;
  left: 0;

  .btn {
    margin-bottom: 0;
  }

  &.fm-alert-success {
    background-color: $alertSuccessBackgroundColor;
  }

  &.fm-alert-info {
    color: $fmWhite;
    background-color: $alertInfoBackgroundColor;
  }

  &.fm-alert-warning {
    background-color: $alertWarningBackgroundColor;
  }

  &.fm-alert-error {
    background-color: $alertErrorBackgroundColor;
  }

  &-btn-success {
    background-color: #1d3d2a;
  }

  .close-btn {
    position: absolute;
    right: 16px;
    cursor: pointer;
    margin-left: 8px;
    font-size: 21px;
    color: $fmBlack;
    margin-top: 3px;
  }

  &-title {
    font-weight: 600;
  }

  &-message {
    color: $fmBlack;
  }

  &-phone-link {
    color: $fmBlack;
    border-bottom: 1px solid transparentize($fmBlack, 0.5);
    white-space: nowrap;

    &:hover {
      color: $fmBlack;
      border-bottom: 1px solid $fmBlack;
    }
  }

  @include media-breakpoint-up(sm) {
    &-text {
      margin-right: 16px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-right: 16px;

    &-text {
      margin-right: 64px;
    }

    .btn {
      margin-top: 16px;
      width: 100%;
    }
  }

  #fm-alert-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  #fm-alert-button-wrapper {
    text-align: end;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}
