.checkbox {
  $fmCheckboxSize: 24px;
  $fmCheckboxPadding: 20px;
  $fmCheckboxMarkerWidth: 14px;
  $fmLabelPadding: 16px;
  $fmCheckBoxInset: $fmCheckboxSize + $fmLabelPadding;

  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 $fmCheckBoxInset;

    font-size: 1rem;
    font-weight: 300;

    .checkbox-label {
      transform: translateY(-$fmCheckboxSize - 2);
    }
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: $fmCheckboxSize;
    height: $fmCheckboxSize;

    border: 1px solid $fm88;
    box-shadow: 0;
    box-shadow: none;
    background-color: $fmF7;
    background-clip: inherit;

    transform: translateX(-$fmCheckBoxInset);
  }

  // Box hover
  &:hover + label:before {
    background-color: $fmF7;
  }

  // Box focus
  &:focus + label:before {
    border: 2px solid black;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    opacity: 0.3;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 8px;
    width: $fmCheckboxMarkerWidth * 0.5;
    height: $fmCheckboxMarkerWidth;
    border: solid $fmBlack;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
