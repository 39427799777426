@mixin rotate() {
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}

@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
