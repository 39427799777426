@import "base/index";
@import "npm:@fielmann-ag/design_basics-icons/css/FielmannIcons.css";

@import "reset";
@import "alert-page";

#auth0 {
  .auth0-lock {
    font-family: $fmFont;
  }

  .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 100%;
  }

  .auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .auth0-lock-content {
    padding: 0;
  }

  .auth0-lock-icon {
    display: none;
  }

  .auth0-lock-input-wrap {
    padding-left: 0;
    background: #fff;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    border-radius: 0;
    font-size: 1rem;
  }

  input:not(select),
  .auth0-lock-input,
  .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    border: 1px solid $fm88;
    box-shadow: 0;
    box-shadow: none;
    background-color: $fmF7;
    background-clip: inherit;
    height: 40px;

    &[readonly] {
      cursor: default;
      opacity: 0.3;
    }

    &:focus {
      box-shadow: none;
      border-color: $fm88;
      background-color: #fff;
      border-radius: 0;

      &:not([readonly]) {
        border-bottom: 2px solid $fmBlack;
        margin-bottom: -1px;

        &:not(.is-invalid) {
          height: 41px;
        }
      }
    }

    &.is-invalid,
    &.is-invalid:focus {
      border-color: $formControlInvalid;
      box-shadow: 0 0 0 1px $formControlInvalid;
      border-bottom-width: 1px;
      margin-bottom: 0;
    }
  }

  .auth0-lock-submit {
    display: none;
  }

  .auth0-lock-header {
    display: none;
  }

  .auth0-lock-form {
    p:first-of-type {
      display: none;
    }
  }

  .auth0-lock-badge-bottom {
    display: none;
  }
}

.greeting-text {
  margin-bottom: 32px;
  max-width: 600px;
}

.reset-form-button-container {
  margin-top: 32px;
}
