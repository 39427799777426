.split-content-container {
  display: flex;

  .content-col-wrapper {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .content-col {
      width: 100%;
      margin: 64px 32px 64px 64px;

      @include media-breakpoint-up(sm) {
        max-width: 448px;
      }

      @include media-breakpoint-only(sm) {
        margin: 64px 16px 64px 64px;
      }

      @include media-breakpoint-only(xs) {
        margin: 64px 16px;
      }
    }
  }

  .icon-col-wrapper {
    width: 50%;
    display: flex;
    justify-items: center;
    padding: 16px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .icon-col {
      margin-top: 5%;
      opacity: 0.03;
      height: 100%;
      width: 100%;
      max-height: 635px;
      max-width: 635px;
    }
  }
}
