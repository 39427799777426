#alert-page {
  .icon {
    font-size: 48px;
    width: 100%;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .info-icon {
    @extend .icon;
    color: $alertInfoBackgroundColor;
  }

  .success-icon {
    @extend .icon;
    color: $alertSuccessBackgroundColor;
  }

  .error-icon {
    @extend .icon;
    color: $formControlInvalid;
  }

  #button-container {
    margin-top: 32px;
  }
}
