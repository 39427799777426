.radio {
  $formRadioCheckMarkColor: #000000;
  $formRadioFocusedColor: #000000;
  $formRadioBackgroundColor: $fmF7;

  position: absolute;
  left: -9999px;

  + label {
    position: relative;
    padding-left: 32px;
    padding-top: 2px;
    margin-right: 16px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
  }

  + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $formCheckboxBorderColor;
    border-radius: 100%;
    background: $formRadioBackgroundColor;
  }

  + label:after {
    content: "";
    width: 14px;
    height: 14px;
    background: $formRadioCheckMarkColor;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
  }

  &[readonly] {
    cursor: default;
    opacity: 0.3;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  &:focus + label:before {
    border: 2px solid $formRadioFocusedColor;
  }
}

.form-check {
  padding-left: 0;
}

.form-check-inline {
  margin-right: 0;
}
