.un-primary-menu__container {
  box-shadow: $regular-border;
  padding: $pad $pad * 1.5 $pad $pad * 2;
  display: flex;
  justify-content: space-between;
  @media all and (min-width: $breakpoint-tablet) {
    padding: $pad * 1.5 $pad * 2 $pad * 1.5 $pad * 3;
  }
}

.un-primary-menu {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: $primary-menu-height;
  justify-content: space-between;
  position: relative;
}

.un-primary-menu__brand__container {
  flex: 1 0 auto;
  padding: 0 0 5px;
}

.un-primary-menu__brand {
  font-size: 20px;
}

.un-primary-menu__logo {
  height: 16px;
  @media all and (min-width: $breakpoint-tablet) {
    height: 24px;
  }
  vertical-align: middle;
}

.un-login-logout {
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;

  &--hidden {
    display: none;
  }

  &__icon {
    font-size: 34px;
  }
}

.un-login-logout__label {
  display: none;
  @media all and (min-width: $breakpoint-mobile) {
    display: block;
    padding: 0 0 0 0.5 * $pad;
  }
  white-space: nowrap;

  &--hidden {
    display: none;
  }
}
