#kc-reset-wrapper {
  .greeting-text-container {
    margin-bottom: 0;
  }

  .greeting-text {
    max-width: 600px;
    margin-bottom: 32px;
  }

  .split-grid-col-2 {
    display: block;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .reset-form-button-container {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }
  }

  .form-group {
    margin-bottom: 32px;
  }

  #grey-area {
    min-height: 100vh;
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: $fmWhite;
  background-color: #000;
  border-color: initial;
}
