html {
  width: 100%;
  font-size: 112.5%;
  max-width: 100vw;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
}

// https://github.com/cypress-io/cypress/issues/3200 (still occurs)
html.cypress-tests {
  scroll-behavior: inherit !important;
}

body {
  position: relative;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: $fmBlack;
  background: $fmWhite;
  font-family: $fmFont;
  padding-top: 48px;

  @include media-breakpoint-up(md) {
    padding-top: 64px;
  }
}

.default-wrapper,
.default-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  > ng-component {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}

p {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.content-container {
  position: relative;
  width: calc(100% - (16px * 2));
  max-width: 896px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    width: calc(100% - (64px * 2));
  }
}

.content {
  margin: 32px 0;

  @include media-breakpoint-up(md) {
    margin: 64px 0;
  }
}

.text-link {
  color: $fmBlack;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1px;

  &:hover {
    color: $fmBlack;
    border-bottom: 1px solid $fm66;
  }
}

a {
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

h1,
h2 {
  font-size: 1rem;
  line-height: 24px;
  margin: 0 0 16px;
  font-weight: 600;

  &.mobile-large {
    font-size: 2.167rem;
    line-height: 1.6;
    margin: 0 0 32px;
    font-weight: normal;
  }

  @include media-breakpoint-up(sm) {
    font-size: 2.167rem;
    line-height: 1.6;
    margin: 0 0 32px;
    font-weight: normal;
  }
}

.greeting-text-container {
  .greeting-text {
    @include media-breakpoint-up(sm) {
      max-width: 600px;
    }
  }

  h2 {
    @include media-breakpoint-up(sm) {
      font-size: 1.778rem;
      font-weight: 600;
      line-height: 46px;
      margin: 0 0 16px;
    }
  }
}

.alert-container {
  .alert {
    margin: 32px 0 0;
  }
}

.text-link-arrow {
  color: black;
  font-size: 9px;
  padding-left: 5px;
}
