.split-grid-wrapper {
  width: 100%;
  overflow-x: hidden;
  flex-grow: 1;

  @include media-breakpoint-up(sm) {
    background: linear-gradient(
      to right,
      $fmF7 0%,
      $fmF7 50%,
      $fmLG 50%,
      $fmLG 100%
    );
  }

  &.light-bg {
    @include media-breakpoint-up(sm) {
      background: linear-gradient(
        to right,
        $fmWhite 0%,
        $fmWhite 50%,
        $fmF7 50%,
        $fmF7 100%
      );
    }
  }

  &.white-bg {
    background: white;
  }

  .split-grid-container {
    @include media-breakpoint-up(sm) {
      display: flex;
      .split-grid-col-1,
      .split-grid-col-2 {
        position: relative;
        flex-shrink: 0;
        width: 50%;
      }
    }

    .split-grid-col-1 {
      padding: 32px 16px 20px 0;

      @include media-breakpoint-up(md) {
        padding: 32px 32px 20px 0;
      }

      @include media-breakpoint-only(xs) {
        padding: 32px 0 20px 0;
      }
    }

    .split-grid-col-2 {
      @include media-breakpoint-down(sm) {
        background: $fmLG;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

.split-grid-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .split-grid-icon-container {
    color: $fmE8;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 400px;
    line-height: 1;
    right: -50px;
  }
}
