@import "variables";
@import "menu";

.un-header,
.un-footer {
  //vendor specific attributes
  -moz-osx-font-smoothing: grayscale; // for Mac OSX only
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; // for Mac OSX only
  -webkit-text-size-adjust: 100%;

  box-sizing: border-box;
  font-family: $fmFontFamily;
  font-size: $fmFontSizeEpsilon;
  font-weight: $fmSansRegular;
  line-height: normal;
  width: 100%;
}

.un-header {
  background-color: $white;
  left: 0;
  letter-spacing: $fmLetterSpacingEpsilon;
  position: fixed;
  top: 0;
  z-index: $z-index-header;
}

.un-footer {
  background-color: $fmGray200;
  font-size: $fmFontSizeZeta;
  letter-spacing: $fmLetterSpacingZeta;
  color: $footer-grey;
  position: relative;
  text-align: left;
  z-index: $z-index-footer;
}

.un-footer__back-to-top {
  background-color: $white;
  border-radius: 50%;
  color: $fmBlack;
  cursor: pointer;
  font-size: 21px;
  height: $back-to-top-size;
  @media (min-width: $breakpoint-tablet) {
    left: auto;
    right: -0.5 * $back-to-top-size;
  }
  left: 50%;
  line-height: 38px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-indent: 1px;
  transform: translate(-50%, -50%);
  width: $back-to-top-size;
}

.un-footer__content-section {
  background-color: $dark-grey;
  margin: 0 auto;
  max-width: 896px;
  @media (min-width: $breakpoint-desktop) {
    max-width: 1110px;
  }
  position: relative;
  width: calc(100% - 2 * #{$footer-margin-small});
  @media (min-width: $breakpoint-tablet) {
    width: calc(100% - 2 * #{$footer-margin-large});
  }
}

.un-footer__content {
  display: flex;
  flex-direction: column;
  padding: 68px 0 0;

  [class*="un-icon"] {
    font-size: 28px;
  }

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }
}

.un-footer__section {
  padding: 54px 0 0 0;

  @media (min-width: $breakpoint-desktop) {
    padding-top: 0;
    padding-bottom: 3 * $pad;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 20px;
  }
}

.un-footer__section-heading {
  color: $white;
  font-size: inherit;
  font-weight: 500;
  margin: 0 0 $pad 0;
  min-height: 23px;

  &:empty {
    margin-top: -86px;

    @media (min-width: $breakpoint-desktop) {
      margin-top: 0;
    }
  }
}

.un-footer__customer-service-section {
  color: $footer-text;
  flex-grow: 1;

  @media (min-width: $breakpoint-desktop) {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

.un-footer__customer-service-section__link {
  color: inherit;
  padding: 0 0 0 0;
  text-decoration: none;
  white-space: nowrap;
}

.un-footer__customer-service-section__text {
  color: inherit;
  line-height: normal;
  margin: 0;
  padding: $pad 0 0 0;
}

.un-footer__customer-service-section__email,
.un-footer__customer-service-section__phone {
  align-items: center;
  display: flex;
  height: 23px;
  margin: 0 0 $pad 0;
}

.un-footer__customer-service-section__phone {
  margin: 0 0 14px 0;
}

.un-footer__section-spacer {
  flex-grow: 1;
}

.un-footer__link-section {
  @media (min-width: $breakpoint-desktop) {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

.un-footer__link-section__item {
  list-style: none;
  margin: 6px 0;
}

.un-footer__list {
  margin: 0;
  padding: 0;
}

.un-footer__list-item__link {
  color: inherit;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    color: $white;
  }
}

.un-footer__copyright-container {
  border-top: 1px solid $footer-border;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }
  padding: 0 0 $pad 0;
}

.un-footer__copyright {
  color: $white;
  margin: 0 0 $pad 0;
  padding: $pad 0 0 0;
}

.un-footer__copyright-text {
}

.un-footer__copyright-container__logo {
}

.un-footer__fm-link {
  padding: 0 0 0 0;
  @media (min-width: $breakpoint-desktop) {
    margin-left: auto;
    padding: $pad 0 0 0;
  }
}
