.form-group {
  margin-bottom: 16px;
}

.form-control:not(select) {
  border: 1px solid $fm88;
  box-shadow: 0;
  box-shadow: none;
  background-color: $fmF7;
  background-clip: inherit;
  height: 40px;

  &[readonly] {
    cursor: default;
    opacity: 0.3;
  }

  &:focus {
    box-shadow: none;
    border-color: $fm88;

    &:not([readonly]) {
      border-bottom: 2px solid $fmBlack;
      margin-bottom: -1px;

      &:not(.is-invalid) {
        height: 41px;
      }
    }
  }

  &.is-invalid,
  &.is-invalid:focus {
    border-color: $formControlInvalid;
    box-shadow: 0 0 0 1px $formControlInvalid;
    border-bottom-width: 1px;
    margin-bottom: 0;
  }
}

label {
  font-size: 0.777rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.form-label {
  margin-bottom: 4px;
}

.form-text,
.invalid-feedback {
  margin-top: 8px;
  font-size: 0.777rem;
}

.invalid-feedback {
  font-weight: 500;
  line-height: 1;
  color: $formControlInvalid !important;
}

.form-text {
  color: $fmMG;
  line-height: 1.6;
}

.feedback-text {
  display: block;

  &.hidden {
    display: none;
  }
}

.btn {
  border: 0;
  height: 48px;
  padding: 10px 56px;
  outline: 0;

  @include media-breakpoint-up(sm) {
    height: 40px;
    padding: 6px 56px;
  }

  background-color: $formButtonBackground;
  color: $formButtonColor;
  margin-bottom: 8px;
  font-size: 1rem;
  white-space: nowrap;

  &.btn-m {
    $lateral-padding: 16px;

    padding-left: $lateral-padding;
    padding-right: $lateral-padding;
  }

  &.btn-primary {
    background-color: $formButtonBackground;
    border-color: $formButtonBackground;
  }

  &:hover {
    background-color: $formButtonBackgroundHover;
    border-color: $formButtonBackgroundHover;
    color: $fmWhite;
  }

  &:not(:disabled):active {
    background-color: $formButtonBackgroundActive !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    color: $formButtonDisabledColor;
    background-color: $formButtonDisabledBackground;
    border-color: $formButtonDisabledBackground;
  }

  &.btn-light {
    background-color: $formButtonBackgroundLight;
    border-color: $formButtonBackgroundLight;
    color: $formButtonColorLight;

    &:hover {
      color: $formButtonColorLight;
    }

    &:active {
      background-color: $fmBlack !important;
      border-color: $fmBlack !important;
      color: $fmWhite !important;
    }
  }
}

.form-wrapper {
  a {
    @extend .text-link;
  }

  .form-links {
    font-size: 0.7778rem;
    font-weight: 300;
    text-align: left;
    margin-top: 8px;
  }

  .form-button-container {
    text-align: left;
    margin-top: 32px;

    .btn {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }

  .hint-text {
    color: $fm99;
    font-size: 0.777rem;
    margin-top: 8px;
    line-height: 1;
  }
}

input:read-only {
  background-color: rgb(235, 235, 228);
  border: 1px solid #999;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: $formControlInvalid;
}
